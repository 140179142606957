import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSettings} from "../../hooks/Settings";

import TenantsComponent from "./TenantsComponent";
import Loader from "../../components/loader/Loader";

const TenantsContainer = (props) => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const optionsModals = t(`servicesTenants.options`, {returnObjects: true});
    const {settings} = useSettings();

    const [showModal, setShowModal] = useState(optionsModals);

    const handleShowModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = true;
        setShowModal(showModalCopy);
    }

    const handleCloseModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = false;
        setShowModal(showModalCopy);
    }

    if(!settings.isLoaded) {
        return <Loader />;
    }

    return (
        <TenantsComponent
            settings={settings.data[i18n.language === 'en' ? 'us' : i18n.language]}
            showModal={showModal}
            handleShowModal={handleShowModal}
            handleCloseModal={handleCloseModal}
        />
    );
};

export default TenantsContainer;