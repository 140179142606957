import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import MainButton from "../main-button/MainButton";

import './Steps.scss';

const StepsComponent = (props) => {

    return (
        <div className={`steps-section ${props.color}`}>

            <Container>
                <h2 className="section-title">{props.title}</h2>

                <Row>
                    {props.steps.map((step, index) => {
                        return (
                            <div key={index} className={`col-step col-step-${props.steps.length} text-center d-flex flex-md-column align-items-center`}>
                                {step.modalInfo &&
                                <Modal show={props.showModal[index].modalInfo.open}
                                       onHide={() => props.handleCloseModal(index)}
                                       className="custom-modal step-modal" centered
                                       scrollable
                                       animation={false}>
                                    <Modal.Header closeButton className="header-modal">
                                        <h2 className={`section-title ${props.color}-text`}>
                                            <strong>Pasul {index + 1}</strong>
                                        </h2>
                                    </Modal.Header>

                                    <h3 className="section-title" dangerouslySetInnerHTML={{__html: step.title}}/>
                                    <p dangerouslySetInnerHTML={{__html: step.modalInfo.modalDescription}}/>
                                    {step.modalInfo.buttonTitle &&
                                    <div className="cursor-pointer">
                                        <MainButton href="#services" title={step.modalInfo.buttonTitle} onClick={() => props.handleCloseModal(index)}/>
                                    </div>
                                    }
                                </Modal>
                                }
                                <img src={step.image} alt=""/>
                                <div className="step-number">
                                    <span><strong>{index + 1}</strong></span>
                                </div>
                                <div className="description">
                                    <p dangerouslySetInnerHTML={{__html: step.title}}/>
                                    {step.modalInfo &&
                                    <button onClick={() => props.handleShowModal(index)}
                                            className="link unstyled-button">Vezi
                                        detalii
                                    </button>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default StepsComponent;