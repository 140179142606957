import React, {useEffect, useState} from 'react';

export const useSettings = () => {

	const [settings, setSettings] = useState({
		error: null,
		isLoaded: false,
		data: null
	});

	useEffect(() => {
		fetch("https://backend-staging.milluu.com/api/settings.json")
		  .then(res => res.json())
		  .then(
			(result) => {
				setSettings({
					...settings,
					isLoaded: true,
					data: result.data
				})
			},
			(error) => {
				setSettings({
					...settings,
					isLoaded: true,
					error: error.message && "Error, please try again!"
				})
			}
		  )
	},[])

	useEffect(() => {
		if(settings.error) {
			alert(settings.error)
		}
	}, [settings.error])

	return {settings};
};