import React from 'react';

import HomeComponent from "./HomeComponent";

const HomeContainer = () => {

    return (
        <HomeComponent />
    );
};

export default HomeContainer;