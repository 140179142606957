import React from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";

import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import HomeContainer from "./containers/home/HomeContainer";
import OwnersContainer from "./containers/owners/OwnersContainer";
import TenantsContainer from "./containers/tenants/TenantsContainer";
import PartnersContainer from "./containers/partners/PartnersContainer";

const Routes = (props) => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route exact path="/">
                    <HomeContainer />
                </Route>
                <Route exact path="/proprietari">
                    <OwnersContainer />
                </Route>
                <Route exact path="/chiriasi">
                    <TenantsContainer />
                </Route>
                <Route exact path="/parteneri">
                    <PartnersContainer />
                </Route>
            </Switch>
        </>
    );
};

export default Routes;