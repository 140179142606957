import React, {useState} from 'react';

import StepsComponent from "./StepsComponent";

const StepsContainer = (props) => {
    const [showModal, setShowModal] = useState(props.steps);

    const handleShowModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = true;
        setShowModal(showModalCopy);
    }

    const handleCloseModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = false;
        setShowModal(showModalCopy);
    }

    return (
        <StepsComponent
            {...props}
            showModal={showModal}
            handleShowModal={handleShowModal}
            handleCloseModal={handleCloseModal}
        />
    );
};

export default StepsContainer;