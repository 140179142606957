import {useEffect, useState} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {useTranslation} from "react-i18next";
import Loader from "./components/loader/Loader";

import Layout from "./layout/Layout";
import Routes from "./routes";

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

const tagManagerArgs = {
	gtmId: 'GTM-T8DSVRL'
}

TagManager.initialize(tagManagerArgs);

function App() {
	return (
	  <Router>
		  <Layout>
			  <div className="App">
				  <Routes />
			  </div>
		  </Layout>
	  </Router>
	);
}

export default App;
