import React from 'react';
import Slider from "react-slick";

import Container from "react-bootstrap/Container";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './SliderTerstimonials.scss';

const SliderTestimonials = (props) => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        fade: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="slider-wrapper">
            <Container>
                <h2 className="section-title">{props.children}</h2>

                <Slider {...settings} className="slider-testimonials">
                    {props.testimonials.map((item, index) => {
                        return (
                            <div key={index} className="testimonial d-md-flex">
                                <div className="description">
                                    <p>{item.description}</p>
                                </div>
                                <div className="info-author">
                                    <div className="img">
                                        <img src={item.avatar} alt=""/>
                                    </div>
                                    <p>
                                        <strong>{item.name},</strong> {item.age}
                                        <br/>
                                        <span className="category">{item.category}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </Container>
        </div>
    );
};

export default SliderTestimonials;