import React from 'react';
import {useTranslation, Trans} from "react-i18next";

import Container from "react-bootstrap/Container";

import illustrationHome from './img/illustration-home-circle.svg';
import illustrationWhatIsMilluu from './img/illustration-what-is-milluu.svg';
import btnAndroid from './img/btn-android.svg';
import btnIos from './img/btn-ios.svg';

import './WhatIsMilluu.scss';

const WhatIsMilluu = () => {
    const { t } = useTranslation();

    return (
        <div className="what-is-milluu">
            <Container>
                <div className="d-flex flex-wrap">
                    <div className="description">
                        <div className="section-title-wrapper">
                            <img src={illustrationHome} alt=""/>
                            <h2 className="section-title"><Trans i18nKey="about.title" /></h2>
                        </div>
                        <p className="intro-text">{t(`about.description`)}</p>
                        <ul className="unstyled-list">
                            {(t(`about.features`, { returnObjects: true })).map((feature, index) => {
                                return (
                                    <li key={index}><Trans i18nKey={`about.features.${index}`} /></li>
                                )
                            })}
                        </ul>
                        
                        <div className="buttons-group">
                            <a href="https://play.google.com/store/apps/details?id=com.stardevelopment.milluu&hl=ro"><img src={btnAndroid} alt=""/></a>
                            <a href="https://apps.apple.com/app/id1466056170?l=ro"><img src={btnIos} alt=""/></a>
                        </div>
                    </div>

                    <div className="img">
                        <img src={illustrationWhatIsMilluu} alt=""/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default WhatIsMilluu;