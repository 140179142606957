import React from 'react';

import PartnersComponent from "./PartnersComponent";

const PartnersContainer = () => {
    return (
        <PartnersComponent />
    );
};

export default PartnersContainer;