import React from 'react';

import Container from "react-bootstrap/Container";

import './Hero.scss';

const Hero = (props) => {
    return (
        <div className={`hero ${props.className ? props.className : ''}`}>
            <img className="bg-image" src={props.background} alt=""/>
            <img className="bg-gradient" src={props.gradient} alt=""/>
            <Container>
                <div className="description">
                    {props.children}
                </div>
            </Container>
        </div>
    );
};

export default Hero;