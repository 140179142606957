import React from 'react';

import HeaderComponent from "./HeaderComponent";

const HeaderContainer = () => {
    return (
        <HeaderComponent />
    );
};

export default HeaderContainer;