import React from 'react';
import {useTranslation, Trans} from "react-i18next";

import Hero from "../../components/hero/Hero";
import Features from "../../components/features/Features";
import StepsContainer from "../../components/steps/StepsContainer";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import MainButton from "../../components/main-button/MainButton";

import bgHero from "./img/bg-hero-parteneri.jpg";
import gradientHero from "./img/bg-gradient-parteneri.png";

import '../../resources/styles/secondary-lp.scss';
import './Partners.scss';

const PartnersComponent = (props) => {

    const {t} = useTranslation();

    return (
        <div className="partners-component secondary-lp">
            <Hero className="custom-hero" background={bgHero} gradient={gradientHero}>
                <div className="wrapper-hero-description d-flex flex-column align-items-center">
                    <h1><Trans i18nKey={`bannerPartners.title`}/></h1>
                </div>
                <MainButton title={t(`bannerPartners.button.title`)} href={t(`bannerPartners.button.link`)} shadow />
            </Hero>

            <div className="wrapper-section-under-hero">
                <Features features={t(`featuresPartners.features`, { returnObjects: true })}>
                    <h2 className="section-title section-features-title border-color-violet">{t(`featuresPartners.title`)}</h2>
                    <p className="section-title"><strong>{t(`featuresPartners.subtitle`)} <br/>
                        <span className="violet-text">{t(`featuresPartners.coloredText`)}</span></strong></p>
                </Features>
            </div>

            <StepsContainer steps={t(`stepsPartners.steps`, { returnObjects: true })} title={<Trans i18nKey={`stepsPartners.title`}/>} color="violet"/>

            <div id="app">
                <WhatIsMilluu/>
            </div>
        </div>
    );
};

export default PartnersComponent;