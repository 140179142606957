import React from 'react';
import {useTranslation, Trans} from "react-i18next";
import TextLoop from "react-text-loop"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container from "react-bootstrap/Container";

import Hero from "../../components/hero/Hero";
import MainButton from "../../components/main-button/MainButton";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import SliderTestimonials from "../../components/slider-testimonials/SliderTestimonials";

import bgHero from './img/bg-hero-home.jpeg';
import gradientHero from './img/bg-gradient-home.png';
import illustrationProprietar from '../../resources/img/illustration-proprietar.svg';
import illustrationChirias from '../../resources/img/illustration-chirias.svg';
import ctaProprietari from './img/cta-proprietari.jpg';
import ctaChiriasi from './img/cta-chiriasi.jpg';

import './Home.scss';

const HomeComponent = (props) => {

    const settingsSliderNews = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }

        ]
    };

    const {t} = useTranslation();

    return (

        <div className="home-component">
            <Hero background={bgHero} gradient={gradientHero}>
                <div
                    className="wrapper-hero-description d-flex align-items-md-end align-items-start justify-content-center">
                    <img src={illustrationProprietar} alt=""/>
                    <h1>
                        {t("bannerHome.0.title.0")} <br className="d-lg-none"/>
                        <TextLoop springConfig={{stiffness: 100, damping: 20}}
                                  adjustingSpeed={350} textAlignCenter>
                            <span>{t("bannerHome.0.title.1")}</span>
                            <span>{t("bannerHome.0.title.2")}</span>
                        </TextLoop>
                        <br className="d-lg-none"/> {t("bannerHome.0.title.3")}
                    </h1>
                    <img src={illustrationChirias} alt=""/>
                </div>
                <span className="main-button">
                    <TextLoop springConfig={{stiffness: 100, damping: 20}}
                              adjustingSpeed={350} textAlignCenter>

                        <a href={t(`bannerHome.0.button.0.link`)}>{t(`bannerHome.0.button.0.title`)}</a>
                        <a href={t(`bannerHome.0.button.1.link`)}>{t(`bannerHome.0.button.1.title`)}</a>
                        {/*<MainButton title={t(`bannerHome.0.button.0`)} to="/" shadow/>
                        <MainButton title={t(`bannerHome.0.button.1`)} to="/" shadow/>*/}
                    </TextLoop>
                </span>

            </Hero>

            <div className="wrapper-section-under-hero">
                <WhatIsMilluu/>
            </div>

            <div className="section-cta">
                <Container>
                    <div className="row-cta d-flex flex-wrap">
                        <div className="col-cta">
                            <div className="box-wrapper">
                                <div className="img">
                                    <img src={ctaProprietari} alt=""/>
                                </div>
                                <div className="description">
                                    <h2 className="section-title"><Trans i18nKey={`callToActionOwners.title`}/></h2>
                                    <p><Trans i18nKey={`callToActionOwners.description`}/></p>
                                    <MainButton title={t(`callToActionOwners.button`)} to="/proprietari" color="orange"
                                                shadow/>
                                </div>
                            </div>
                        </div>
                        <div className="col-cta">
                            <div className="box-wrapper">
                                <div className="img">
                                    <img src={ctaChiriasi} alt=""/>
                                </div>
                                <div className="description">
                                    <h2 className="section-title"><Trans i18nKey={`callToActionTenants.title`}/></h2>
                                    <p><Trans i18nKey={`callToActionTenants.description`}/></p>
                                    <MainButton title={t(`callToActionTenants.button`)} to="/chiriasi" color="green"
                                                shadow/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* ./section-cta */}

            <SliderTestimonials testimonials={t(`reviewsHome.reviews`, {returnObjects: true})}>
                <Trans i18nKey={`reviewsHome.title`}/>
            </SliderTestimonials>

            <div className="section-news">
                <Container>
                    <Slider {...settingsSliderNews} className="slider-news">
                        {t(`news`, {returnObjects: true}).map((item, index) => {
                            return (
                                <div key={index}>
                                    <a href={item.link} target="_blank" rel="noreferrer" className="d-flex col-news">
                                        <img src={item.image} alt=""/>
                                        <p>{item.description}</p>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </Container>
            </div>
        </div>
    );
};

export default HomeComponent;