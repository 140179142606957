import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";

import logo from '../../resources/img/logo.svg';
import iconAndroid from './img/icon-android.svg';
import iconIos from './img/icon-ios.svg';
import ro from './img/ro.svg';
import en from './img/en.png';

import './Header.scss';

const lngs = {
    ro: {nativeName: 'Romana', icon: ro},
    en: {nativeName: 'English', icon: en}
};

const HeaderComponent = () => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();

    return (
        <div className="header-component">
            <Container>
                <Navbar expand="lg" className="main-nav" collapseOnSelect>
                    <Navbar.Brand>
                        <Link to="/"><img src={logo} alt=""/></Link>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="menu-items">
                            <div className="links"> {/* add class active on link-nav */}
                                {(t(`menu.links`, { returnObjects: true })).map((link, index) => {
                                    return <Nav.Link key={index} eventKey={index} className="link-nav" as={Link} to={link.link}>{link.title}</Nav.Link>
                                })}
                            </div>
                            <div className="links">
                                <a className="link-app" href="https://play.google.com/store/apps/details?id=com.stardevelopment.milluu&hl=ro"><img src={iconAndroid} alt=""/></a>
                                <a className="link-app" href="https://apps.apple.com/app/id1466056170?l=ro"><img src={iconIos} alt=""/></a>
                            </div>
                            <div className="links">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" id="dropdown-language" className="dropdown-language-btn">
                                        {Object.keys(lngs).map((lng) => (
                                            <div key={lng} className={`flag-wrapper ${i18n.language !== lng && 'd-none'}`}>
                                                <img src={lngs[lng].icon} alt=""/>
                                            </div>
                                        ))}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {Object.keys(lngs).map((lng) => (
                                            <Dropdown.Item key={lng}
                                                           className={i18n.language === lng && 'selected'}
                                                           type="submit"
                                                           onClick={() => i18n.changeLanguage(lng)}>
                                                <div className="flag-wrapper">
                                                    <img src={lngs[lng].icon} alt=""/>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    );
};

export default HeaderComponent;