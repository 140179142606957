import React from 'react';
import {useTranslation, Trans} from "react-i18next";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import Hero from "../../components/hero/Hero";
import Features from "../../components/features/Features";
import StepsContainer from "../../components/steps/StepsContainer";
import SliderTestimonials from "../../components/slider-testimonials/SliderTestimonials";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import MainButton from "../../components/main-button/MainButton";

import bgHero from '../home/img/bg-hero-home.jpeg';
import gradientHero from './img/bg-gradient-proprietari.png';
import illustrationChirias from "../../resources/img/illustration-chirias.svg";
import milluuCarefree from '../../resources/img/milluu-carefree.svg';
import iconInfo from "../../resources/img/icon-info.svg";
import iconCheck from "../../resources/img/icon-check.svg";

import '../../resources/styles/secondary-lp.scss';
import './Tenants.scss';

const TenantsComponent = (props) => {

    const {t} = useTranslation();

    let packagesCopy = [...props.settings.packagesTenant];
    packagesCopy[0] = JSON.parse(packagesCopy[0].replace('\"', "").replace('feeUpfront"', '"feeUpfront"'));

    return (
        <div className="tenants-component secondary-lp">
            <Hero className="custom-hero" background={bgHero} gradient={gradientHero}>
                <div className="wrapper-hero-description d-flex flex-column align-items-center">
                    <img src={illustrationChirias} alt=""/>
                    <h1><Trans i18nKey={`bannerTenants.title`}/></h1>
                </div>
                <MainButton title={t(`bannerTenants.button.title`)} href={t(`bannerTenants.button.link`)} shadow />
            </Hero>

            <div className="wrapper-section-under-hero">
                <Features features={t(`featuresTenants.features`, { returnObjects: true })}>
                    <h2 className="section-title section-features-title border-color-green">{t(`featuresTenants.title`)}</h2>
                    <p className="section-title"><strong>{t(`featuresTenants.subtitle`)} <br/>
                        <span className="green-text">{t(`featuresTenants.coloredText`)}</span></strong></p>
                </Features>
            </div>

            <StepsContainer steps={t(`stepsTenants.steps`, { returnObjects: true })} title={<Trans i18nKey={`stepsTenants.title`}/>} color="green"/>

            <div className="section-costs">
                <Container>
                    <h2 className="section-title"><Trans i18nKey={`servicesTenants.title`}/></h2>
                    <div className="table-services">
                        <div className="box-wrapper">
                            <div className="d-flex align-items-center title-table">
                                <img src={milluuCarefree} alt=""/>
                                <h2 className="plan-title section-title orange-text"><strong>Milluu Zen</strong></h2>
                            </div>
                            <div className="options">
                                {(t(`servicesTenants.options`, { returnObjects: true })).map((option, optIndex) => {
                                    return (
                                        <div key={optIndex} className="option d-flex align-items-center justify-content-md-between">
                                            <Modal show={props.showModal[optIndex].modalInfo.open} onHide={() => props.handleCloseModal(optIndex)}
                                                   className="custom-modal step-modal" centered scrollable>
                                                <Modal.Header closeButton className="header-modal">
                                                    <h2 className="section-title small-header"><strong>{option.title} </strong></h2>
                                                </Modal.Header>
                                                <p><Trans i18nKey={option.modalInfo.description}/></p>
                                            </Modal>

                                            <p>{option.title} <button
                                                className="unstyled-button info-btn"
                                                onClick={() => props.handleShowModal(optIndex)}><img src={iconInfo} alt=""/>
                                            </button></p>
                                            <img src={iconCheck} alt=""/>
                                        </div>
                                    )
                                })}
                            </div>
                            {(t(`servicesTenants.fees`, { returnObjects: true })).map((fee, index) => {
                                return (
                                    <div key={index} className="info-wrapper">
                                        <h3>
                                            <Trans
                                                i18nKey={`servicesTenants.fees.${index}.title`}
                                                values={{ percent: packagesCopy[0].feeUpfront, percentMonth: packagesCopy[0].feeMonthly, min: packagesCopy[0].feeMinMonthly }}
                                                components={{ bold: <strong/> }}
                                            />
                                        </h3>
                                        <p>{fee.description}</p>
                                        <p className="obs">{fee.observation}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </div>

            <SliderTestimonials testimonials={t(`reviewsTenants.reviews`, { returnObjects: true })}>
                <Trans i18nKey={`reviewsTenants.title`}/>
            </SliderTestimonials>

            <div id="app">
                <WhatIsMilluu/>
            </div>
        </div>
    );
};

export default TenantsComponent;