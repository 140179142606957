import React from 'react';
import {useTranslation, Trans} from "react-i18next";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import Hero from "../../components/hero/Hero";
import Features from "../../components/features/Features";
import StepsContainer from "../../components/steps/StepsContainer";
import SliderTestimonials from "../../components/slider-testimonials/SliderTestimonials";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import MainButton from "../../components/main-button/MainButton";

import bgHero from '../home/img/bg-hero-home.jpeg';
import gradientHero from './img/bg-gradient-chiriasi.png';
import illustrationProprietar from "../../resources/img/illustration-proprietar.svg";
import milluuCarefree from '../../resources/img/milluu-carefree.svg';
import milluuRiskFree from '../../resources/img/milluu-riskfree.svg';
import iconInfo from '../../resources/img/icon-info.svg';
import iconCheck from '../../resources/img/icon-check.svg';
import iconLine from '../../resources/img/icon-line.svg';

import '../../resources/styles/secondary-lp.scss';
import './Owners.scss';


const OwnersComponent = (props) => {

    const {t} = useTranslation();

    let packagesCopy = [...props.settings.packagesLister];
    packagesCopy[0] = JSON.parse(packagesCopy[0].replace('\"', "").replace('id"', '"id"'));
    packagesCopy[1] = JSON.parse(packagesCopy[1].replace('\"', "").replace('id"', '"id"'));

    return (
        <div className="owners-component secondary-lp">
            <Hero className="custom-hero" background={bgHero} gradient={gradientHero}>
                <div className="wrapper-hero-description d-flex flex-column align-items-center">
                    <img src={illustrationProprietar} alt=""/>
                    <h1><Trans i18nKey={`bannerOwners.title`}/></h1>
                </div>
                <MainButton title={t(`bannerOwners.button.title`)} href={t(`bannerOwners.button.link`)} shadow />
            </Hero>

            <div className="wrapper-section-under-hero">
                <Features features={t(`featuresOwners.features`, { returnObjects: true })}>
                    <h2 className="section-title section-features-title border-color-orange">{t(`featuresOwners.title`)}</h2>
                    <p className="section-title"><strong>{t(`featuresOwners.subtitle`)} <br/>
                        <span className="orange-text">{t(`featuresOwners.coloredText`)}</span></strong></p>
                </Features>
            </div>

            <StepsContainer steps={t(`stepsOwners.steps`, { returnObjects: true })} title={<Trans i18nKey={`stepsOwners.title`}/>} color="yellow" />

            <div className="section-costs" id="services">
                <Container>
                    <div className={`table-services ${props.tableActiveColumn}`}>
                        <div className="invisible-row">
                            <div className="col-row col-invisible-2" onClick={() => props.handleSetTableActiveColumn('active-2')} />
                            <div className="col-row col-invisible-3" onClick={() => props.handleSetTableActiveColumn('active-3')} />
                        </div>
                        <div className="row-head">
                            <div className="col-row">
                                <h2 className="section-title text-center text-md-start"><Trans i18nKey={`servicesOwners.title`}/></h2>
                            </div>
                            <div className="col-row">
                                <img src={milluuCarefree} alt=""/>
                                <h2 className="plan-title section-title orange-text">
                                    {<Trans i18nKey={`servicesOwners.categories.0.title`}/>}</h2>
                            </div>
                            <div className="col-row">
                                <img src={milluuRiskFree} alt=""/>
                                <h2 className="plan-title section-title violet-text">
                                    <Trans i18nKey={`servicesOwners.categories.1.title`}/></h2>
                            </div>
                        </div>
                        <div className="row-table d-flex d-md-none">
                            <div className="col-row d-none" />
                            <div className="col-row" />
                            <div className="col-row" />
                        </div>
                        {(t(`servicesOwners.options`, { returnObjects: true })).map((option, index) => {
                            return (
                                <div key={index} className="row-table">
                                    <Modal show={props.showModal[index].modalInfo.open} onHide={() => props.handleCloseModal(index)}
                                           className="custom-modal step-modal" centered scrollable>
                                        <Modal.Header closeButton className="header-modal">
                                            <h2 className="section-title small-header"><strong>{option.title} </strong></h2>
                                        </Modal.Header>
                                        <p><Trans i18nKey={option.modalInfo.description}/></p>
                                    </Modal>

                                    <div className="col-row">
                                        <p>{option.title} <button
                                            className="unstyled-button info-btn"
                                            onClick={() => props.handleShowModal(index)}><img src={iconInfo} alt=""/>
                                        </button></p>
                                    </div>
                                    <div className="col-row">
                                        <img src={option.plan1 ? iconCheck : iconLine} alt=""/>
                                    </div>
                                    <div className="col-row">
                                        <img src={option.plan2 ? iconCheck : iconLine} alt=""/>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="row-footer">
                            <div className="col-row"/>
                            {(t(`servicesOwners.categories`, { returnObjects: true })).map((col, catIndex) => {
                                return (
                                    <div key={catIndex} className="col-row" onClick={() => props.handleSetTableActiveColumn(`active-${catIndex + 2}`)}>
                                        {col.fees.map((category, index) => {
                                            return (
                                                <div key={index}>
                                                    <Modal show={props.showModalFee[catIndex].fees[index].modalInfo.open} onHide={() => props.handleCloseModalFee(catIndex, index)}
                                                           className="custom-modal step-modal" centered scrollable>
                                                        <Modal.Header closeButton className="header-modal">
                                                            <h2 className="section-title small-header"><strong><Trans i18nKey={category.title}/> </strong>{category.description}</h2>
                                                        </Modal.Header>
                                                        <p><Trans i18nKey={category.modalInfo.description}/></p>
                                                    </Modal>
                                                    <p>
                                                        <strong><Trans i18nKey={category.title}/> </strong>
                                                        <button className="unstyled-button info-btn"
                                                                onClick={() => props.handleShowModalFee(catIndex, index)}><img src={iconInfo} alt=""/>
                                                        </button>
                                                        <br/>
                                                        <span>{t(`servicesOwners.categories.${catIndex}.fees.${index}.description`, {percent: packagesCopy[catIndex].feeUpfront, percentMonth: packagesCopy[catIndex].feeMonthly, min: packagesCopy[catIndex].feeMinMonthly})}</span>
                                                    </p>
                                                </div>
                                            )
                                        })}
                                        <MainButton href={col.button.link} title={col.button.title}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </div>

            <SliderTestimonials testimonials={t(`reviewsOwners.reviews`, { returnObjects: true })}>
                <Trans i18nKey={`reviewsOwners.title`}/>
            </SliderTestimonials>

            <WhatIsMilluu/>
        </div>
    );
};

export default OwnersComponent;