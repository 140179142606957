import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './i18n';

const loading = (
  <h1>Loading...</h1>
);

ReactDOM.render(
  <Suspense fallback={loading}>
      <React.StrictMode>
          <App />
      </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
