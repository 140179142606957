import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo from '../../resources/img/logo.svg';
import iconFacebook from './img/icon-facebook.svg';
import iconLinkedin from './img/icon-linkedin.svg';
import iconInstagram from './img/icon-instagram.svg';

import './Footer.scss';

const FooterComponent = () => {

    const {t} = useTranslation();

    return (
        <div className="footer-component">
            <Container>
                <div className="footer-logo-wrapper d-flex justify-content-between align-items-center">
                    <img className="footer-logo" src={logo} alt=""/>
                    <ul className="socials d-flex justify-content-lg-end unstyled-list d-lg-none">
                        <li><a href="https://facebook.com/milluuapp/" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                        <li><a href="https://linkedin.com/company/milluu" title="LinkedIn" target="_blank"><img src={iconLinkedin} alt="LinkedIn" /></a></li>
                        <li><a href="https://instagram.com/milluu_app/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                    </ul>
                </div>
                <Row className="justify-content-between align-items-end">
                    <Col lg={6} className="order-lg-2">
                        <ul className="socials d-lg-flex justify-content-lg-end unstyled-list d-none">
                            <li><a href="https://facebook.com/milluuapp/" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                            <li><a href="https://linkedin.com/company/milluu" title="LinkedIn" target="_blank"><img src={iconLinkedin} alt="LinkedIn" /></a></li>
                            <li><a href="https://instagram.com/milluu_app/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                        </ul>
                        <ul className="links-menu d-lg-flex justify-content-lg-end unstyled-list">
                            {(t(`footer.menuLinks`, { returnObjects: true })).map((link, index) => {
                                return link.external === true ? (
                                    <li key={index}><Link to={{pathname: link.link}} target="_blank">{link.title}</Link></li>
                                ) : (
                                    <li key={index}><Link to={link.link}>{link.title}</Link></li>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col lg={6} className="order-lg-1">
                        <p>2021© Millluu. Toate drepturile rezervate</p>
                        <ul className="terms-menu d-flex flex-wrap unstyled-list">
                            {(t(`footer.termsLinks`, { returnObjects: true })).map((link, index) => {
                                return <li key={index}><Link to={{pathname: link.link}} target="_blank">{link.title}</Link></li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterComponent;